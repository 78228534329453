import Rusha from 'rusha';

let worker: Worker;
let nextTaskId: number;
let cbs: {
    [index: number]: Function
};

function init() {
    worker = Rusha.createWorker();
    nextTaskId = 1;
    cbs = {}; // taskId -> cb

    worker.onmessage = function onRushaMessage(e) {
        let taskId = e.data.id;
        let cb = cbs[taskId];
        delete cbs[taskId];

        if (e.data.error != null) {
            cb(new Error('Rusha worker error: ' + e.data.error));
        } else {
            cb(null, e.data.hash);
        }
    };
}

function sha1(data: Blob): Promise<string>
function sha1(data: string): string
function sha1(data: string | Blob) {
    if (typeof data === 'string') {
        return Rusha.createHash().update(data).digest('hex');
    }

    if (!worker) init();
    return new Promise((resolve, reject) => {
        cbs[nextTaskId] = (error: Error, result: string) => {
            if (error) {
                reject(error);
            } else {
                resolve(result);
            }
        };

        worker.postMessage({ id: nextTaskId, data: data });
        nextTaskId += 1;
    });
}

export default sha1;
