import { castArray } from 'lodash';
import { Editor, Element, NodeMatch, Path } from 'slate';

export type PredicateObj<T> = Partial<Record<keyof T, any | any[]>>;
export type PredicateFn<T> = (obj: T) => boolean;
export type Predicate<T> = PredicateObj<T> | PredicateFn<T>;


/**
 * Match the object with a predicate object or function.
 * If predicate is:
 * - object: every predicate key/value should be in obj.
 * - function: it should return true.
 */
export const match = <T>(obj: T, predicate?: Predicate<T>): boolean => {
    if (!predicate) return true;

    if (typeof predicate === 'object') {
        return Object.entries(predicate).every(([key, value]) => {
            const values = castArray<any>(value);

            return values.includes(obj[key as keyof T]);
        });
    }

    return predicate(obj);
};

export const matchPredicate = <T>(predicate?: Predicate<T>) => (obj: T) =>
    match(obj, predicate);


export interface MatchOptions {
    match?: Predicate<Element & { [key: string]: any }>;
    block?: boolean;
    root?: Path;
}

export type WithQueryOptions<T extends (...args: any) => any, index extends number, O = Parameters<T>[index]> = O extends {
    match?: NodeMatch<any>
} ?
    Omit<O, 'match'> & MatchOptions : never

/**
 * Extended query options for slate queries:
 * - `match` can be an object predicate where one of the values should include the node value.
 * Example: { type: ['1', '2'] } will match the nodes having one of these 2 types.
 */
export const getQueryOptions = <T extends MatchOptions>(editor: Editor, options?: T) => {

    if (!options) {
        return;
    }

    let queryOptions: Omit<T, 'match'> & { match?: NodeMatch<any> } = {
        ...options,
        match: undefined
    };

    if ('match' in options || 'block' in options || 'root' in options) {
        queryOptions.match = (n, p) =>
            (Element.isElement(n) && match(n, options.match)) &&
            (!options?.block || Editor.isBlock(editor, n)) &&
            (!options?.root || Path.isAncestor(options.root, p));
    }

    return queryOptions;
};
