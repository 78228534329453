import { PropsWithChildren } from 'react';
import { Spinner, SpinnerProps } from 'react-bootstrap';
import Dimmer from './dimmer';

interface LoaderProps {
    loading?: boolean | string;
    animation?: SpinnerProps['animation'];
}

export default function Loader({
    loading = true,
    children,
    variant = 'primary',
    animation = 'border',
    ...props
}: PropsWithChildren<LoaderProps & Omit<SpinnerProps, 'animation'>>) {
    if (!loading) {
        return null;
    }

    if (typeof loading === 'string') {
        children = loading;
    }

    if (children) {
        children = <p className='mt-3 text-secondary'>{children}</p>;
    }

    return <Dimmer inverted active>
        <Spinner animation={animation} variant={variant} {...props} />
        {children}
    </Dimmer>;
}
