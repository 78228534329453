import { AnchorHTMLAttributes, forwardRef } from 'react';
import { isUrl } from '@topwrite/common';

const Anchor = forwardRef<HTMLAnchorElement, AnchorHTMLAttributes<HTMLAnchorElement>>(function({
    href,
    ...props
}, ref) {
    if (href) {
        if (isUrl(href)) {
            props.target = '_blank';
        } else {
            href = '#!';
        }
    }
    return <a href={href} ref={ref} {...props} rel='noopener noreferrer' />;
});

export default Anchor;
