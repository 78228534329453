import {
    cloneElement,
    createContext,
    forwardRef,
    ImgHTMLAttributes,
    PropsWithChildren,
    ReactElement,
    ReactNode,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import File from '../entities/file';
import { socket } from '../lib/socket';
import createObjectUrl from '../lib/create-object-url';
import { styled, useAsync, useSelector } from '@topwrite/common';
import { Spinner } from 'react-bootstrap';
import { ReactComponent as ImageError } from '../images/image-error.svg';
import { useStateIfMounted } from 'use-state-if-mounted';

const ImageCacheContext = createContext(new Map);

export const ImageCacheProvider = ({ children }: { children: ReactNode }) => {

    const [cache, setCache] = useState(new Map);
    const { current } = useSelector('workspace');

    useEffect(() => {
        cache.forEach((url) => {
            window.URL.revokeObjectURL(url);
        });

        setCache(new Map);
    }, [current]);

    return <ImageCacheContext.Provider value={cache}>
        {children}
    </ImageCacheContext.Provider>;
};

export const useImageCache = () => {
    return useContext(ImageCacheContext);
};

interface LocalImageProps extends ImgHTMLAttributes<HTMLImageElement> {
    src: string;
    default?: string;
    width?: number | string;
    height?: number | string;
    autoReload?: boolean;
}

const LocalImage = forwardRef<HTMLImageElement, PropsWithChildren<LocalImageProps>>(function({
    autoReload = false,
    ...props
}, ref) {

    const filename = useMemo(() => {
        return decodeURIComponent(props.src);
    }, [props.src]);

    const imageCache = useImageCache();

    const [url, setUrl] = useStateIfMounted<string | null>(() => {
        if (imageCache.has(filename)) {
            return imageCache.get(filename);
        }
    });

    const { loading, execute } = useAsync(async (filename, force) => {
        if (imageCache.has(filename) && !force) {
            setUrl(imageCache.get(filename));
        } else {
            const content = await socket.readFile(filename);

            if (content.length > 0) {
                const file = new File(filename, content);
                const url = createObjectUrl(file);
                imageCache.set(filename, url);
                setUrl(url);
            } else {
                imageCache.set(filename, null);
                setUrl(null);
            }
        }
    }, [filename, false]);

    useEffect(() => {
        if (autoReload) {
            const listener = function(names: string[]) {
                if (names.includes(filename)) {
                    return execute(filename, true);
                }
            };

            socket.on('file.change', listener);

            return () => {
                socket.off('file.change', listener);
            };
        }
    }, [filename, autoReload]);

    if (url === undefined && loading) {
        return <Spinner as={'span'} size='sm' animation='border' variant='success' />;
    }

    const src = url || props.default;

    if (src) {
        if (props.children) {
            return cloneElement(props.children as ReactElement, { src, ref });
        } else {
            return <img ref={ref} {...props} src={src} />;
        }
    }

    return <ErrorIcon />;
});

export default LocalImage;

const ErrorIcon = styled(ImageError)`
  width: calc(1em + 10px);
  padding: 5px;
`;
