import { forwardRef, VideoHTMLAttributes } from 'react';
import { fromAsset, isAsset } from '../../lib/asset';
import { useOptions } from '@topwrite/common';
import useFilename from '../../lib/use-filename';

const Video = forwardRef<HTMLVideoElement, VideoHTMLAttributes<HTMLVideoElement>>(function({ src, ...props }, ref) {

    const { asset } = useOptions();

    const filename = useFilename(src);

    if (filename) {
        if (isAsset(filename)) {
            src = asset + fromAsset(filename);
        }
    }

    return <video ref={ref} src={src} {...props} />;
});

export default Video;
