import { Editor, Location, Range, Span } from 'slate';

export interface UnhangRangeOptions {
    at?: Span | Location;
    voids?: boolean;
    unhang?: boolean;
}

export function unhangRange(editor: Editor, options: UnhangRangeOptions = {}) {
    const { at = editor.selection, voids, unhang = true } = options;

    if (Range.isRange(at) && unhang) {
        options.at = Editor.unhangRange(editor, at, { voids });
    }
}
