import {
    ContainerDirectiveElement,
    DirectiveLabelElement,
    Editor,
    Element,
    LeafDirectiveElement,
    NodeEntry,
    Range,
    TextDirectiveElement
} from 'slate';
import { RenderElementProps, RenderLeafProps } from 'slate-react';
import { DOMHandlers } from './utils/dom-handlers';
import { ComponentType } from 'react';
import ElementMenu from './element-menu';
import ToolBarItem from './tool-bar/item';
import HoverBarItem from './hover-bar/item';

export type WithOverride = <T extends Editor = Editor>(editor: T) => T & Editor;
export type Decorate = (entry: NodeEntry) => Range[];

type EventHandlers = {
    [key in keyof DOMHandlers]?: (editor: Editor) => DOMHandlers[key]
};

export interface ElementProps<T extends Element = Element> extends RenderElementProps {
    element: T;
    ElementMenu: typeof ElementMenu;
}

export type RenderElement =
    {
        [E in Element as E['type']]?: ComponentType<ElementProps<E>>
    } & {
        [index in `text:${string}`]?: ComponentType<ElementProps<TextDirectiveElement>>
    } & {
        [index in `container:${string}`]?: ComponentType<ElementProps<ContainerDirectiveElement>>
    } & {
        [index in `leaf:${string}`]?: ComponentType<ElementProps<LeafDirectiveElement>>
    } & {
        [index in `label:${string}`]?: ComponentType<ElementProps<DirectiveLabelElement>>
    };

export type RenderLeaf = (props: RenderLeafProps) => JSX.Element;

export type ToolBarItemType = ComponentType<{
    Component: typeof ToolBarItem;
}>;

export type ToolBarItems = {
    [position: `${number}`]: ToolBarItemType;
    [position: `${number}:${number}`]: ToolBarItemType;
};

export type HoverBarItemType = ComponentType<{
    Component: typeof HoverBarItem;
}>;

export type HoverBarItems = {
    [position: `${number}`]: HoverBarItemType;
    [position: `${number}:${number}`]: HoverBarItemType;
};

export default interface PlatePlugin extends EventHandlers {
    renderElement?: (editor: Editor) => (RenderElement | undefined);
    renderLeaf?: (editor: Editor) => (RenderLeaf | undefined);
    voidTypes?: string[] | string;
    inlineTypes?: string[] | string;
    withOverrides?: WithOverride | WithOverride[];
    decorate?: (editor: Editor) => Decorate | void;
    available?: (editor: Editor) => boolean;
    priority?: number;
    tools?: (editor: Editor) => ToolBarItems;
    hoverTools?: (editor: Editor) => HoverBarItems;
}

export const createPlatePlugin = (plugin: PlatePlugin) => {
    return plugin;
};

export const createMarkdownPlatePlugin = (plugin: PlatePlugin) => {
    return {
        ...plugin,
        available(editor: Editor) {
            if (!editor.isMarkdown) {
                return false;
            }
            return !plugin.available || plugin.available(editor);
        }
    };
};
