import {
    Book,
    Config,
    Level,
    Summary,
    SummaryArticle,
    SummaryPart,
    SummaryPartShape,
    SummaryArticleShape
} from '@topwrite/common';
import { isEmpty, repeat } from 'lodash';
import { immerable } from 'immer';

Book.prototype[immerable] = true;

Summary.prototype[immerable] = true;
Summary.prototype.insertPart = function(part, level) {
    if (!(part instanceof SummaryPart)) {
        part = SummaryPart.createFromObject(part);
    }

    if (this.isEmpty()) {
        this.parts = [part as SummaryPart];
    } else {
        const index = level ? level.getLeafIndex() : this.parts.length;
        this.parts.splice(index, 0, part as SummaryPart);
    }

    this.indexLevels();
};
Summary.prototype.removePart = function(level) {
    const index = level.getLeafIndex();
    this.parts.splice(index, 1);

    if (this.parts.length === 0) {
        this.parts = [new SummaryPart('')];
    }
    this.indexLevels();
};
Summary.prototype.updatePart = function(level, data) {
    const part = this.getByLevel(level);
    if (part instanceof SummaryPart) {
        part.title = data.title;
        part.metadata = data.metadata;
    }
};
Summary.prototype.insertArticle = function(article, level) {
    if (!(article instanceof SummaryArticle)) {
        article = SummaryArticle.createFromObject(article);
    }

    const parent = this.getParent(level);

    if (!parent) {
        return;
    }

    const articles = parent.getArticles();

    const index = level.getLeafIndex();

    articles.splice(index, 0, article as SummaryArticle);

    parent.indexLevels();
};
Summary.prototype.updateArticle = function(level, data) {
    const article = this.getByLevel(level);
    if (article instanceof SummaryArticle) {
        article.title = data.title;
        article.ref = data.ref;
        article.metadata = data.metadata;
    }
};
Summary.prototype.removeArticle = function(level) {
    const parent = this.getParent(level);
    if (!parent) {
        return;
    }
    const articles = parent.getArticles();
    const index = level.getLeafIndex();
    articles.splice(index, 1);
    parent.indexLevels();
};
Summary.prototype.toText = function() {
    let text = '';
    if (this.parts.length === 1 && !this.parts[0].title) {
        text += this.parts[0].toText();
    } else {
        for (let part of this.parts) {
            text += `## ${part.title}`;
            text += `\n`;
            text += part.toText();
            text += '\n';
        }
    }
    return text;
};

SummaryPart.prototype[immerable] = true;
SummaryPart.prototype.toText = function() {
    let text = '';
    SummaryArticle.findArticle(this, (article: SummaryArticle) => {
        const line = article.ref ? `[${article.title}](${article.ref.replace(/\s/g, '%20')})` : article.title;
        text += `${repeat('    ', article.getDepth() - 1)}* ${line}\n`;
        return false;
    });
    return text;
};

SummaryArticle.prototype[immerable] = true;

Config.prototype[immerable] = true;
Config.prototype.toText = function() {
    const obj = this.toObject();
    let text = '';
    if (!isEmpty(obj)) {
        text = JSON.stringify(obj, null, 4);
    }
    return text;
};

declare module '@topwrite/common' {

    export interface Book {
        [immerable]: boolean;
    }

    export interface Summary {
        [immerable]: boolean;

        insertPart(part: SummaryPart | SummaryPartShape, level?: Level): void;

        updatePart(level: Level, data: SummaryPartShape): void;

        removePart(level: Level): void;

        insertArticle(article: SummaryArticle | SummaryArticleShape, level: Level): void;

        updateArticle(level: Level, data: SummaryArticleShape): void;

        removeArticle(level: Level): void;

        toText(): string;
    }

    export interface SummaryPart {
        [immerable]: boolean;

        toText(): string;
    }

    export interface SummaryArticle {
        [immerable]: boolean;
    }

    export interface BaseConfig {
        [immerable]: boolean;
    }

    export interface Config {
        [immerable]: boolean;

        toText(): string;
    }

}
