import path from 'path';
import { isUrl, useSelector } from '@topwrite/common';

export default function useFilename(src?: string) {
    const { current } = useSelector('workspace');
    if (src && !isUrl(src)) {
        return path.join(path.dirname(current || ''), src);
    }
    return undefined;
}
