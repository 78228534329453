import { createMessage, MessageProps, wrapMessage } from './message';
import { memo, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { FormProps, IChangeEvent } from '@rjsf/core';
import localize from './localize';
import Button from '../button';
import Form, { FormType } from '../form';

export interface PromptOptions<T> extends Pick<FormProps<T>, 'schema' | 'validate' | 'formData' | 'uiSchema' | 'onChange' | 'idPrefix'> {
    title: string;
    size?: 'sm' | 'lg' | 'xl';
    layout?: 'vertical' | 'horizontal';
}

const Prompt = function <T>({
    state,
    resolve,
    message: {
        title,
        size,
        layout,
        ...props
    }
}: MessageProps<PromptOptions<T>, T>) {

    const formRef = useRef<FormType>(null);

    const handleSubmit = ({ formData }: IChangeEvent) => {
        resolve(formData);
    };

    return <Modal {...state} size={size} backdrop={'static'}>
        <Modal.Header>
            <Modal.Title as='h5'>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form ref={formRef} {...props} formContext={{ layout }} onSubmit={handleSubmit} />
        </Modal.Body>
        <Modal.Footer>
            <Button variant='secondary' onClick={() => resolve()}>{localize('cancel')}</Button>
            <Button onClick={() => {
                formRef.current?.submit().catch(() => void 0);
            }}>{localize('ok')}</Button>
        </Modal.Footer>
    </Modal>;
};

export default function prompt<T>(options: PromptOptions<T>) {
    return createMessage(wrapMessage<PromptOptions<T>, T>(memo(Prompt<T>)))(options);
}
