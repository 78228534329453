import { findNode, FindNodeOptions } from './find-node';
import { Editor } from 'slate';

/**
 * Iterate through all of the nodes in the editor and break early for the first truthy match. Otherwise
 * returns false.
 */
export const someNode = (editor: Editor, options: FindNodeOptions) => {
    return Boolean(editor.selection && findNode(editor, { at: editor.selection, ...options }));
};
