import { AttachmentHTMLAttributes, forwardRef } from 'react';
import { useOptions } from '@topwrite/common';
import { fromAsset, isAsset } from '../../lib/asset';
import useFilename from '../../lib/use-filename';

const Attachment = forwardRef<HTMLAttachmentElement, AttachmentHTMLAttributes<HTMLAttachmentElement>>(function(
    { src, ...props },
    ref
) {
    const { asset } = useOptions();

    const filename = useFilename(src);

    if (filename) {
        if (isAsset(filename)) {
            src = asset + fromAsset(filename);
        }
    }

    return <t-attachment ref={ref} src={src} {...props} />;
});

export default Attachment;
