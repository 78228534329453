import { useOptions } from '@topwrite/common';
import { fromAsset, toAsset, uploadAsset } from './asset';

//废弃的 改用useAsset
export default function useAssetUploader() {
    const { asset } = useOptions();

    const upload = async (file: File, onProgress?: (event: ProgressEvent) => void) => {
        const filename = await uploadAsset(file, { host: asset, onProgress });
        return toAsset(filename);
    };

    const getUrl = (filename: string) => {
        return `${asset}/${fromAsset(filename)}`;
    };

    return { upload, getUrl };
}
