import { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { createMessage, MessageProps, wrapMessage } from './message';
import localize from './localize';
import Button from '../button';

const Alert: FC<MessageProps<string>> = function({ state, message, resolve }) {

    return <Modal {...state} backdrop='static'>
        <Modal.Header>
            <Modal.Title as='h5'>{localize('confirm')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
            <Button onClick={() => resolve()}>{localize('ok')}</Button>
        </Modal.Footer>
    </Modal>;
};

export default function alert(message: string) {
    return createMessage(wrapMessage(Alert))(message);
}
