import { Editor, Range, Transforms } from 'slate';

export const wrapText = (editor: Editor, prefix: string, suffix: string = prefix) => {

    const { selection } = editor;

    if (selection) {

        Editor.withoutNormalizing(editor, () => {
            const start = Range.start(selection);
            const text = Editor.string(editor, selection);

            if (text) {
                editor.deleteFragment();
            }

            editor.insertText(prefix + text + suffix);

            Transforms.select(editor, {
                anchor: {
                    ...start,
                    offset: start.offset + prefix.length
                },
                focus: {
                    ...start,
                    offset: start.offset + prefix.length + text.length
                }
            });

        });
    }
};
