import { Node, NodeEntry, Path, Text } from 'slate';

export const getLastChild = <N extends Node = Node>(nodeEntry: NodeEntry): NodeEntry<N> | null => {
    const [node, path] = nodeEntry;

    if (Text.isText(node)) return null;
    if (!node.children.length) return null;

    const children = node.children as N[];

    return [children[children.length - 1], path.concat([children.length - 1])];
};

export const getLastChildPath = (nodeEntry: NodeEntry): Path => {
    const lastChild = getLastChild(nodeEntry);

    if (!lastChild) return nodeEntry[1].concat([-1]);

    return lastChild[1];
};

export const isLastChild = (parentEntry: NodeEntry, childPath: Path): boolean => {
    const lastChildPath = getLastChildPath(parentEntry);

    return Path.equals(lastChildPath, childPath);
};
