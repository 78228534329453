import confirm from './confirm';
import prompt from './prompt';
import alert from './alert';
import localize, { setLocale } from './localize';
import { ElementType, MouseEvent, ReactNode, useMemo, useState } from 'react';
import { ButtonProps, Modal as ReactModal, ModalProps as ReactModalProps } from 'react-bootstrap';
import Button from '../button';
import show from './show';

export interface ModalProps extends Pick<
    ReactModalProps,
    'backdrop' | 'centered' | 'size' | 'scrollable' | 'fullscreen' | 'onHide' | 'onShow' | 'onEnter' | 'onEntering' | 'onEntered' | 'onExit' | 'onExiting' | 'onExited'
> {
    children: ReactNode;
    title: ReactNode;
    footer?: ReactNode | ((args: { okButton: ReactNode, cancelButton: ReactNode }) => ReactNode);
    okText?: string;
    cancelText?: string;
    closable?: boolean;
    show?: boolean;
    onOk?: (e: MouseEvent) => void;
    onCancel?: (e: MouseEvent) => void;
    okButtonProps?: ButtonProps;
    bodyAs?: ElementType;
    titleAs?: ElementType;
    confirmLoading?: boolean;
}

const Modal = ({
    title,
    children,
    footer,
    closable = true,
    show,
    cancelText,
    okText,
    onOk,
    onCancel,
    onHide,
    okButtonProps,
    bodyAs,
    titleAs = 'h5',
    confirmLoading = false,
    ...rest
}: ModalProps) => {

    const [loading, setLoading] = useState(false);

    const okButton = useMemo(() => {
        return <Button loading={loading || confirmLoading} onClick={async (e) => {
            setLoading(true);
            try {
                await onOk?.(e);
                if (!e.defaultPrevented) {
                    onHide?.();
                }
            } finally {
                setLoading(false);
            }
        }} {...okButtonProps}>{okText || localize('ok')}</Button>;
    }, [okButtonProps, okText, onOk, onHide, loading, confirmLoading]);

    const cancelButton = useMemo(() => {
        return <Button variant='secondary' onClick={(e) => {
            onCancel?.(e);
            if (!e.defaultPrevented) {
                onHide?.();
            }
        }}>{cancelText || localize('cancel')}</Button>;
    }, [onCancel, onHide, cancelText]);

    switch (typeof footer) {
        case 'undefined':
            footer = <>
                {cancelButton}
                {okButton}
            </>;
            break;
        case 'function':
            footer = footer({ okButton, cancelButton });
            break;
    }

    return <ReactModal {...rest} onHide={onHide} show={show}>
        <ReactModal.Header closeButton={closable}>
            <ReactModal.Title as={titleAs}>{title}</ReactModal.Title>
        </ReactModal.Header>
        <ReactModal.Body as={bodyAs}>{children}</ReactModal.Body>
        {footer && <ReactModal.Footer>{footer}</ReactModal.Footer>}
    </ReactModal>;
};

Modal.setLocale = setLocale;
Modal.confirm = confirm;
Modal.prompt = prompt;
Modal.alert = alert;
Modal.show = show;

export default Modal;

export type { MessageProps } from './message';
