import { EventEmitter } from 'eventemitter3';
import { io, Socket as SocketClient, ManagerOptions, SocketOptions } from 'socket.io-client';
import { Config, Summary, VERSION } from '@topwrite/common';
import { Store } from './idb';
import { Buffer } from 'buffer';
import { Commit, Diff, DiffFile, File, Release, State } from 'repo';
import sha1 from './sha1';

interface Message {
    error?: string;
    data?: any;
}

interface SocketSettings {
    url?: string;
    id?: string;
}

export class Socket extends EventEmitter {

    private store: Store;
    private client: SocketClient;

    constructor(options: SocketSettings = {}) {
        super();

        this.store = new Store('topwrite', 'files');
        this.client = this.createClient(options);
    }

    createClient({ url, id }: SocketSettings): SocketClient {
        const query: Record<string, any> = {
            version: VERSION
        };

        if (id) {
            query.id = id;
        }

        const opts: Partial<ManagerOptions & SocketOptions> = {
            transports: ['websocket'],
            withCredentials: true,
            addTrailingSlash: false,
            autoConnect: false,
            query
        };

        const client = url ? io(url, opts) : io(opts);

        client.onAny((event, ...args) => {
            if (event === 'abort') {
                client.disconnect();
            }
            this.emit(event, ...args);
        });

        return client;
    }

    async connect() {
        return new Promise((resolve, reject) => {
            this.client.on('connect', () => {
                resolve(undefined);
            });
            this.client.on('connect_error', reject);
            this.client.connect();
        });
    }

    ready() {
        this.sendMessageAsync('workspace.ready');
    }

    async getState(): Promise<State> {
        return await this.sendMessage('workspace.getState');
    }

    async getConfig(): Promise<Config> {
        const obj = await this.sendMessage('workspace.getConfig');
        return Config.createFromObject(obj);
    }

    async getSummary(): Promise<Summary> {
        const obj = await this.sendMessage('workspace.getSummary');
        try {
            return Summary.create(obj);
        } catch (e) {
            console.error(e);
            return Summary.createFromText('');
        }
    }

    setSummary(summary: string | object) {
        this.sendMessageAsync('workspace.setSummary', summary);
    }

    setConfig(config: object) {
        this.sendMessageAsync('workspace.setConfig', config);
    }

    setCurrent(path: string | null) {
        return this.sendMessage('workspace.setCurrent', path);
    }

    async readDir(root: string = ''): Promise<File[]> {
        return await this.sendMessage('file.readDir', root);
    }

    async readFile(filename: string) {
        let content: Buffer = Buffer.from('');
        let hash: string | null = null;
        const cacheKey = sha1(filename);

        if (await this.store.has(cacheKey)) {
            const cache = await this.store.get<[string, Buffer]>(cacheKey);
            if (cache) {
                hash = cache[0];
                content = Buffer.from(cache[1]);
            }
        }

        const result = await this.sendMessage<string | true>('file.read', filename, hash);

        if (result !== true) {
            const hash = sha1(result);
            content = Buffer.from(result, 'base64');
            await this.store.set(cacheKey, [hash, content]);
        }

        return content;
    }

    async readFileAsString(filename: string): Promise<string> {
        const buffer = await this.readFile(filename);
        return buffer.toString();
    }

    async writeFile(filename: string, content: Buffer): Promise<void> {
        const string = content.toString('base64');
        return this.sendMessage('file.write', filename, string);
    }

    writeFileAsync(filename: string, content: Buffer) {
        const string = content.toString('base64');
        this.sendMessageAsync('file.write', filename, string);
    }

    replaceFileAsync(filename: string, replace: string, line: number, offset: number, length: number) {
        this.sendMessageAsync('file.replace', filename, replace, line, offset, length);
    }

    async removeFile(filename: string) {
        await this.sendMessage('file.remove', filename);
    }

    async renameFile(oldPath: string, newPath: string) {
        await this.sendMessage('file.rename', oldPath, newPath);
    }

    searchFile(query: string): Promise<any[]> {
        return this.sendMessage('file.search', query);
    }

    readDiff(revisions: string | string[], paths?: string | string[]): Promise<DiffFile[]> {
        return this.sendMessage('diff.read', revisions, paths);
    }

    readCommitDiff(hash: string, paths?: string | string[]): Promise<DiffFile[]> {
        return this.sendMessage('commit.diff', hash, paths);
    }

    readHistory(path: string | null = null, offset: number = 0): Promise<Commit[]> {
        return this.sendMessage('history.read', path, offset);
    }

    getReleaseList(page: number = 1): Promise<Paginator<Release>> {
        return this.sendMessage('release.index', page);
    }

    getReleaseStatus(): Promise<Diff> {
        return this.sendMessage('release.status');
    }

    getReleaseDetail(id: number): Promise<Release> {
        return this.sendMessage('release.read', id);
    }

    saveRelease(data: { message: string; }) {
        return this.sendMessage('release.save', data);
    }

    retryRelease(id: number, type: string): Promise<void> {
        return this.sendMessage('release.retry', id, type);
    }

    async readBlobFile(ref: string, file: string) {
        const content: string = await this.sendMessage('blob.readFile', ref, file);
        return Buffer.from(content, 'base64');
    }

    async discard(path?: string) {
        await this.sendMessage('workspace.discard', path);
    }

    sync() {
        this.sendMessageAsync('workspace.sync');
    }

    commit(message: string, release: boolean) {
        this.sendMessageAsync('workspace.commit', message, release);
    }

    setTourVersion(name: string, version: string) {
        this.sendMessageAsync('workspace.setTourVersion', name, version);
    }

    async resolve(files: { [index: string]: string; }) {
        await this.sendMessage('workspace.resolve', files);
    }

    private sendMessageAsync(type: string, ...args: any[]) {
        this.client.emit(type, ...args,);
    }

    private sendMessage<T = any>(type: string, ...args: any[]) {
        return new Promise<T>((resolve, reject) => {
            this.client.emit(type, ...args, (result: Message) => {
                if (result.error) {
                    reject(new Error(result.error));
                } else {
                    resolve(result.data);
                }
            });
        });
    }
}

export let socket: Socket;

export async function createSocket(options: SocketSettings, callback: (socket: Socket) => void) {

    socket = new Socket(options);

    callback(socket);

    await socket.connect();
    return socket;
}
