import { createRoot } from 'react-dom/client';
import { lazy, Suspense } from 'react';
import './extend-entities';

const App = lazy(() => import('../pages/app'));

export default () => {
    const container = document.getElementById('root');

    if (container) {
        const root = createRoot(container);

        root.render(<Suspense fallback={null}>
            <App />
        </Suspense>);
    }
};
