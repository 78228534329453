import { PropsWithChildren } from 'react';
import { styled } from '@topwrite/common';

interface DimmerProps {
    active?: boolean;
    inverted?: boolean;
    className?: string;
}

const Dimmer = function({ active = true, inverted = true, className, children }: PropsWithChildren<DimmerProps>) {
    return <Container className={className} $active={active} $inverted={inverted}>
        {children}
    </Container>;
};

Dimmer.Dimmable = styled.div`
  position: relative;
`;

export default Dimmer;

const Container = styled.div<{
    $active?: boolean;
    $inverted?: boolean;
}>`
  display: ${props => props.$active ? 'flex' : 'none'};
  opacity: ${props => props.$active ? 1 : 0};
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  padding: 1em;
  background-color: ${props => props.$inverted ? 'rgba(255, 255, 255, .85)' : 'rgba(0, 0, 0, .85)'};
  line-height: 1;
  animation-fill-mode: both;
  animation-duration: .5s;
  transition: background-color .5s linear;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
  will-change: opacity;
  z-index: 990;
`;
