import { ReactEditor } from 'slate-react';
import { Editor } from 'slate';

export * from './find-node';
export * from './some-node';
export * from './is-node-focused';
export * from './has-mark';
export * from './elements';
export * from './get-nodes';
export * from './is-expanded';
export * from './is-collapsed';
export * from './get-above';
export * from './get-block-above';
export * from './is-block-above-empty';
export * from './is-start';
export * from './is-end';
export * from './is-block-start';
export * from './is-block-end';
export * from './is-ancestor-empty';
export * from './nodes';
export * from './is-range-across-blocks';
export * from './get-last-child';

const { findPath } = ReactEditor;
const { after, pointRef, start, end, range } = Editor;
export {
    findPath,
    after,
    pointRef,
    start,
    end,
    range
};
