import { isUrl, useOptions } from '@topwrite/common';
import { fromAsset, isAsset, toAsset, uploadAsset } from './asset';

export default function useAsset() {
    const { asset } = useOptions();

    const upload = async (file: File, onProgress?: (event: ProgressEvent) => void) => {
        const filename = await uploadAsset(file, { host: asset, onProgress });
        return toAsset(filename);
    };

    const getUrl = (filename: string) => {
        if (isUrl(filename)) {
            return filename;
        }
        if (isAsset(filename)) {
            return asset + fromAsset(filename);
        }
    };

    return { upload, getUrl };
}
