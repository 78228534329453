import { useCallback, useState } from 'react';

interface Options {
    onHide?: () => void;
    onShow?: () => void;
}

let id = 0;

export interface OverlayState {
    show: boolean;
    onHide: () => void;
    onExited: () => void;
    key: string;
}

export default function useOverlayState({ onHide, onShow }: Options = {}) {
    const [visible, setVisible] = useState(false);

    const [key, setKey] = useState(() => `visible-${++id}`);

    const hide = useCallback(() => {
        setVisible(false);
        if (onHide) {
            onHide();
        }
    }, [setVisible, onHide]);

    const exit = useCallback(() => {
        setKey(`visible-${++id}`);
    }, [setKey, id]);

    const show = useCallback(() => {
        setVisible(true);
        if (onShow) {
            onShow();
        }
    }, [setVisible, onShow]);

    const state: OverlayState = {
        show: visible,
        onHide: hide,
        onExited: exit,
        key
    };

    return {
        visible,
        show,
        hide,
        state
    };
}
