const localize = {
    'zh-CN': {
        'ok': '确定',
        'cancel': '取消',
        'confirm': '确认'
    },
    en: {
        'ok': 'OK',
        'cancel': 'Cancel',
        'confirm': 'Confirm'
    }
};

type Locale = keyof typeof localize;

let locale: Locale = 'zh-CN';

export const setLocale = (value: string) => {
    locale = value as Locale;
};

export default function(id: keyof typeof localize['zh-CN']) {
    return localize[locale][id];
};
