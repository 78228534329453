import { Editor } from 'slate';
import { insertNodes, insertText, select } from '../../transforms';
import { stringifyEntitiesLight } from 'stringify-entities';
import { removeEmptyLineForBlock } from '../markdown/transforms';

function quoted(type: string, key: string, value: string) {

    const quote = '"';
    const subset = type === 'textDirective' ? [quote] : [quote, '\n', '\r'];
    return (
        key +
        (value
            ? '=' + quote + stringifyEntitiesLight(value, { subset }) + quote
            : '')
    );
}

function buildAttrs(type: string, attrs?: Record<string, string>) {
    const values: string[] = [];

    for (const key in attrs) {
        if (
            attrs.hasOwnProperty(key) &&
            attrs[key] !== undefined &&
            attrs[key] !== null
        ) {
            const value = String(attrs[key]);

            values.push(quoted(type, key, value));
        }
    }

    return values.length > 0 ? '{' + values.join(' ') + '}' : '';
}

export interface TextDirective {
    name: string;
    attributes?: Record<string, string>;
    text?: string;
}

export function insertTextDirective(editor: Editor, { name, attributes = {}, text = '' }: TextDirective) {
    if (editor.isDocument) {
        insertNodes(editor, {
            type: 'textDirective',
            children: [
                { text }
            ],
            name,
            attributes
        });
    } else {
        insertText(editor, `:${name}${text ? `[${text}]` : ''}${buildAttrs('textDirective', attributes)}`);
    }
}

export function insertLeafDirective(editor: Editor, { name, attributes = {}, text = '' }: TextDirective) {
    removeEmptyLineForBlock(editor, () => {
        if (editor.isDocument) {
            insertNodes(editor, {
                type: 'leafDirective',
                children: [
                    { text }
                ],
                name,
                attributes
            });
        } else {
            insertNodes(editor, {
                type: 'line',
                children: [{
                    text: `::${name}${text ? `[${text}]` : ''}${buildAttrs('textDirective', attributes)}`
                }]
            });
        }
    });
}

export interface ContainerDirective {
    name: string;
    attributes?: Record<string, string>;
    text?: string;
    children?: ContainerDirective[];
}

export function insertContainerDirective(editor: Editor, directive: ContainerDirective) {
    removeEmptyLineForBlock(editor, () => {
        if (editor.isDocument) {
            const buildNode = (directive: ContainerDirective) => {
                const {
                    name,
                    attributes = {},
                    text = '',
                    children = []
                } = directive;

                return {
                    type: 'containerDirective',
                    name,
                    attributes,
                    children: [
                        {
                            type: 'directiveLabel',
                            name,
                            children: [{ text }]
                        },
                        ...children.map(child => buildNode(child))
                    ]
                };
            };

            const nodes = buildNode(directive);
            const selection = editor.selection;
            if (selection) {
                insertNodes(editor, nodes);
                select(editor, selection);
            }
        } else {
            let nums = 3;

            const buildNodes = (directive: ContainerDirective) => {
                const {
                    name,
                    attributes,
                    text = '',
                    children = []
                } = directive;

                let nodes = children.flatMap(child => buildNodes(child));
                if (nodes.length > 0) {
                    nums++;
                } else {
                    nodes = [
                        {
                            type: 'line',
                            children: [{
                                text: ''
                            }]
                        },
                    ];
                }

                const mark = ':'.repeat(nums);

                return [
                    {
                        type: 'line',
                        children: [{
                            text: `${mark}${name}${text ? `[${text}]` : ''}${buildAttrs('containerDirective', attributes)}`
                        }]
                    },
                    ...nodes,
                    {
                        type: 'line',
                        children: [{
                            text: `${mark}`
                        }]
                    },
                ];
            };

            const nodes = buildNodes(directive);

            insertNodes(editor, nodes);
        }
    });
}
