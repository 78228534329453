import { Editor } from 'slate';
import { insertNodes, insertText } from '../../transforms';
import { removeEmptyLineForBlock } from '../markdown/transforms';

export interface BlockElement {
    name: string;
    value: string;
    parameter?: string;
}

export function insertXBlock(editor: Editor, element: BlockElement) {
    removeEmptyLineForBlock(editor, () => {
        if (editor.isDocument) {
            insertNodes(editor, {
                type: 'xBlock',
                children: [
                    { text: '' }
                ],
                ...element
            });
        } else {

            let name = element.name;
            if (element.parameter) {
                name += `:${element.parameter}`;
            }

            insertNodes(editor, [
                {
                    type: 'line',
                    children: [{
                        text: `\`\`\`[${name}]`
                    }]
                },
                {
                    type: 'line',
                    children: [{
                        text: `${element.value}`
                    }]
                },
                {
                    type: 'line',
                    children: [{
                        text: '\`\`\`'
                    }]
                }
            ]);
        }
    });
}

export interface InlineElement {
    name: string;
    value: string;
    parameter?: string;
}

export function insertXInline(editor: Editor, element: InlineElement) {
    if (editor.isDocument) {
        insertNodes(editor, {
            type: 'xInline',
            children: [
                { text: '' }
            ],
            ...element
        });
    } else {
        const text = `\`${element.name} ${element.value} ${element.name}\``;

        insertText(editor, text);
    }
}
