import { Book, Markdown as CommonMarkdown, useAsync } from '@topwrite/common';
import { ComponentProps, useMemo } from 'react';
import Img from './html/img';
import Attachment from './html/attachment';
import Video from './html/video';
import Audio from './html/audio';
import Anchor from './html/anchor';
import { socket } from '../lib/socket';
import Loader from './loader';

type MarkdownProps = ComponentProps<typeof CommonMarkdown>

export default function Markdown({ children, components, ...props }: MarkdownProps) {

    const { result, loading } = useAsync(async () => {
        const buffer = await socket.readFile(Book.style);

        return buffer.toString();
    }, []);

    const mergedComponents = useMemo(() => {
        return {
            img: Img,
            attachment: Attachment,
            video: Video,
            audio: Audio,
            a: Anchor,
            ...components
        };
    }, [components]);

    if (loading) {
        return <Loader />;
    }

    return <CommonMarkdown css={result} aside={false} {...props} components={mergedComponents}>
        {children}
    </CommonMarkdown>;
}
