import { ComponentType, Fragment, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useStateWithCallback } from '@topwrite/common';
import produce from 'immer';

type MessageWrapProps<T, P = void> = {
    resolve: (value: P | void) => void
    destroy: () => void
    message: T
}

export type MessageProps<T, P = void> = {
    resolve: (value: P | void) => void
    message: T
    state: {
        show: boolean
        onHide: () => void
        onExited: () => void
    }
}

export function wrapMessage<T = {}, P = any>(Component: ComponentType<MessageProps<T, P>>): ComponentType<MessageWrapProps<T, P>> {

    return ({ resolve, destroy, message }) => {

        const [show, setShow] = useStateWithCallback(true);

        const handleResolve = useCallback((value: P | void) => {
            setShow(false, () => {
                resolve(value);
            });
        }, [setShow, resolve]);

        const state = useMemo(() => {
            return {
                show,
                onHide: () => handleResolve(),
                onExited: () => destroy()
            };
        }, [show, handleResolve, destroy]);

        return <Component
            resolve={handleResolve}
            state={state}
            message={message}
        />;
    };
}

export function createMessage<T = {}, P = any>(Component: ComponentType<MessageWrapProps<T, P>>): (message: T) => Promise<P | void> {
    return (message) => {
        return new Promise<P | void>((resolve) => {
            const component = <Component
                resolve={resolve}
                destroy={() => {
                    hide(component);
                }}
                message={message}
            />;

            show(component);
        });
    };
}

type State = Set<ReactNode>

let listener: (state: State) => void = () => void 0;

let memoryState: State = new Set();

export const show = (modal: ReactNode) => {
    memoryState = produce(memoryState, draft => {
        draft.add(modal);
    });
    listener(memoryState);
};

export const hide = (modal: ReactNode) => {
    memoryState = produce(memoryState, draft => {
        draft.delete(modal);
    });
    listener(memoryState);
};

export default function Message() {
    const [state, setState] = useState<State>(memoryState);
    useEffect(() => {
        listener = setState;
    }, []);

    return <>
        {Array.from(state).map((message, index) => <Fragment key={`message-${index}`}>{message}</Fragment>)}
    </>;
}
