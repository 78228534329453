import path from 'path';
import filenamify from 'filenamify/browser';
import { request } from '@topwrite/common';
import sha1 from './sha1';
import uploadFile from './upload-file';

export function isAsset(file: string): boolean {
    return file.startsWith('.topwrite/assets/');
}

export function fromAsset(file: string) {
    return file.substr('.topwrite/assets'.length);
}

export function toAsset(file: string) {
    return '.topwrite/assets/' + file;
}

interface Options {
    host: string;
    onProgress?: (event: ProgressEvent) => void;
}

export async function uploadAsset(file: File, { host, onProgress }: Options) {
    let filename = filenamify(file.name);

    try {
        const { headers } = await request.head(filename, { baseURL: host });

        const hash = headers['etag'];
        if (hash) {
            if (await sha1(file) === hash) {
                return filename;
            } else {
                //自动重命名
                const parsed = path.parse(filename);
                filename = path.join(parsed.dir, `${parsed.name}_${new Date().getTime()}${parsed.ext}`);
            }
        }
    } catch {

    }

    const url = `${host}/${filename}`;

    await uploadFile(url, file, { onProgress });

    return filename;
}
