import { createContext, PropsWithChildren, useContext } from 'react';
import File from '../entities/file';

export const FileContext = createContext<File | undefined>(undefined);

interface Props {
    file?: File;
}

export default function FileProvider({ file, children }: PropsWithChildren<Props>) {
    if (!file) {
        return null;
    }
    return <FileContext.Provider key={file.path} value={file}>
        {children}
    </FileContext.Provider>;
}

export function useFile() {
    const file = useContext(FileContext);
    if (!file) {
        throw new Error('file object not inited');
    }
    return file;
}
