import './typings';
import bootstrap from './lib/bootstrap';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { logHello } from './hello';

logHello();

dayjs.extend(relativeTime);
dayjs.extend(duration);

export * from '@topwrite/common';
export { default as useEditor } from './components/editor/use-editor';
export { default as useEditorActions } from './components/editor/use-editor-actions';
export { socket } from './lib/socket';
export { default as useOverlayState, type OverlayState } from './lib/use-overlay-state';
export { default as useAssetUploader } from './lib/use-asset-uploader';
export { default as useAsset } from './lib/use-asset';
export { useFile } from './components/file-provider';
export { default as Button } from './components/button';
export { default as Modal, type MessageProps } from './components/modal';
export { default as Toast } from './components/toast';
export { default as Markdown } from './components/markdown';
export { default as Form, type FormProps, type FormType } from './components/form';
export { default as Anchor } from './components/html/anchor';
export type { default as PlatePlugin } from './components/editor/plate-plugin';
export { createPlatePlugin, createMarkdownPlatePlugin } from './components/editor/plate-plugin';
export { Overlay, Popover, ProgressBar } from 'react-bootstrap';

bootstrap();
