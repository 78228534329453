import { Editor, Node, ParagraphDepth } from 'slate';
import { elements, someNode } from '../../queries';
import { deleteText, insertText, setNodes } from '../../transforms';
import { repeat } from 'lodash';

type AlignType = 'right' | 'center' | 'left';

type Options = {
    depth?: ParagraphDepth
    align?: AlignType
}

export function inParagraph(editor: Editor) {
    return !editor.selection || !editor.isDocument || someNode(editor, { match: { type: 'paragraph' } });
}

export function isParagraphActive(editor: Editor, options?: Options) {
    return editor.isDocument && someNode(editor, { match: { type: 'paragraph', ...options } });
}

const MARKS = {
    right: '--: ',
    center: ':-: ',
    left: ''
};

export function setAlign(editor: Editor, align: AlignType) {
    if (editor.isDocument) {
        if (isParagraphActive(editor)) {
            setNodes(editor, { align: align }, { match: { type: 'paragraph' } });
        }
    } else {
        const [[node, path]] = elements(editor);
        const text = Node.string(node);
        const inHeadOrBlockquote = text.match(/^(>(\[\w+])?\s)*(\*\s(\[(x|\s)]\s)?|\d\.\s)?(#+\s)?/);
        const markup = MARKS[align];
        let offset = 0;

        if (inHeadOrBlockquote) {
            offset = inHeadOrBlockquote[0].length;
        }

        const hasAlign = text.substr(offset).match(/^:?-+?:?\s/);
        const at = { path: path.concat(0), offset };
        if (hasAlign) {
            deleteText(editor, {
                at,
                distance: hasAlign[0].length
            });
        }
        insertText(editor, markup, { at });
    }
}

export function toggleParagraph(editor: Editor, depth: ParagraphDepth) {
    if (editor.isDocument) {
        if (isParagraphActive(editor)) {
            const active = isParagraphActive(editor, { depth });
            setNodes(editor, { depth: active ? undefined : depth }, { match: { type: 'paragraph' } });
        }
    } else {
        for (const [node, path] of elements(editor)) {
            const text = Node.string(node);
            const isHeading = text.match(/^#+\s/);
            const markup = `${repeat('#', depth)} `;
            const at = { path: path.concat(0), offset: 0 };
            if (isHeading) {
                deleteText(editor, {
                    at,
                    distance: isHeading[0].length
                });
            }
            if (!isHeading || isHeading[0] !== markup) {
                insertText(editor, markup, { at });
            }
        }
    }
}
