import { fromAsset, isAsset } from '../../lib/asset';
import { useOptions } from '@topwrite/common';
import { forwardRef, ImgHTMLAttributes, memo } from 'react';
import LocalImage from '../local-image';
import useFilename from '../../lib/use-filename';

const Img = memo(forwardRef<HTMLImageElement, ImgHTMLAttributes<HTMLImageElement>>(function ({ src, ...props }, ref) {

    const { asset } = useOptions();

    const filename = useFilename(src);

    if (filename) {
        if (isAsset(filename)) {
            src = asset + fromAsset(filename);
        } else {
            return <LocalImage ref={ref} src={filename} {...props} />;
        }
    }

    return <img ref={ref} src={src} {...props} />;
}));


export default Img;
