import { Editor, Element, Transforms } from 'slate';
import { getQueryOptions, WithQueryOptions } from '../utils/match';
import { unhangRange, UnhangRangeOptions } from './unhang-range';

export function wrapNodes(editor: Editor, element: Element, options?: WithQueryOptions<typeof Transforms.wrapNodes, 2> & UnhangRangeOptions) {

    unhangRange(editor, options);

    Transforms.wrapNodes(editor, element, getQueryOptions(editor, options));
}
