import { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { createMessage, MessageProps, wrapMessage } from './message';
import localize from './localize';
import Button from '../button';

interface ConfirmOptions {
    title?: string;
    message: string;
    okText?: string;
    cancelText?: string;
}

const Confirm: FC<MessageProps<ConfirmOptions, true>> = function({
    state,
    message: {
        title,
        message,
        okText,
        cancelText
    },
    resolve,
}) {
    return <Modal {...state}>
        <Modal.Header>
            <Modal.Title as='h5'>{title || localize('confirm')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
            <Button variant='secondary' onClick={() => resolve()}>{cancelText || localize('cancel')}</Button>
            <Button onClick={() => resolve(true)}>{okText || localize('ok')}</Button>
        </Modal.Footer>
    </Modal>;
};

export default function confirm(options: ConfirmOptions) {
    return createMessage(wrapMessage(Confirm))(options);
}
