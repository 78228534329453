import { Alert } from 'react-bootstrap';
import Notification from 'rc-notification';
import { NoticeContent, NotificationInstance } from 'rc-notification/es/Notification';

let noticeInstance: NotificationInstance | null;

function getNoticeInstance(callback: (instance: NotificationInstance) => void) {
    if (noticeInstance) {
        return callback(noticeInstance);
    }

    Notification.newInstance({
        prefixCls: 'notification',
        maxCount: 5,
        style: {
            top: 20,
            right: 20
        }
    }, (instance) => {
        if (noticeInstance) {
            callback(noticeInstance);
            return;
        }
        noticeInstance = instance;
        callback(instance);
    });
}

const types = ['error', 'info', 'success', 'warning'];

const notice = ({ type, ...options }: { type: string } & NoticeContent) => {
    getNoticeInstance((instance) => {
        switch (type) {
            case 'error':
                options.content = <Alert variant='danger'>{options.content}</Alert>;
                if (options.closable) {
                    options.duration = 0;
                } else {
                    options.duration = 5;
                }
                break;
            default:
                options.content = <Alert variant={type}>{options.content}</Alert>;
                options.duration = 3;
                break;
        }

        instance.notice(options);
    });
};


let Toast: {
    [index in typeof types[number]]: (content: string, options?: NoticeContent) => void
} = {};

types.forEach((type) => {
    Toast[type] = (content, options) => {
        notice({
            ...options,
            type,
            content
        });
    };
});

export default Toast;
