import pMap from 'p-map';
import { request } from '@topwrite/common';

interface Options {
    onProgress?: (event: ProgressEvent) => void;
    metadata?: object;
}

export default async function uploadFile(url: string, file: File, { onProgress, metadata = {} }: Options = {}) {
    let loaded = 0;
    const total = file.size;

    const updateProgress = () => {
        const event = new ProgressEvent('progress', {
            lengthComputable: true,
            loaded,
            total
        });

        if (onProgress) {
            onProgress(event);
        }
    };

    if (file.size <= 4 * MB) {
        return await request.put(url, file, {
            headers: {
                'content-type': 'application/octet-stream',
                'x-metadata': JSON.stringify(metadata)
            },
            onUploadProgress: function(e) {
                loaded += e.loaded;
                updateProgress();
            },
        });
    } else {
        const chunks = getChunks(file);

        //initiate
        const { headers: { 'x-id': id } } = await request.put(url, null, {
            headers: { 'x-stage': 'initiate' }
        });

        //upload
        const parts = await pMap(chunks, async (chunk, i) => {
            let chunkLoaded = 0;
            const index = String(i + 1);
            const { headers } = await request.put(url, chunk, {
                headers: {
                    'x-stage': 'upload',
                    'x-id': id,
                    'x-index': index,
                    'content-type': 'application/octet-stream'
                },
                onUploadProgress: function(e) {
                    loaded += e.loaded - chunkLoaded;
                    chunkLoaded = e.loaded;
                    updateProgress();
                },
            });

            loaded += chunk.size - chunkLoaded;
            updateProgress();

            return {
                index,
                etag: headers['etag']
            };
        }, { concurrency: 3 });

        //complete
        return await request.put(url, { parts }, {
            headers: {
                'x-stage': 'complete',
                'x-metadata': JSON.stringify(metadata),
                'x-id': id,
            }
        });
    }

}

const MB = 1024 ** 2;

function getChunks(file: File, blockSize: number = 4): Blob[] {

    let chunkByteSize = blockSize * MB; // 转换为字节
    // 如果 chunkByteSize 比文件大，则直接取文件的大小
    if (chunkByteSize > file.size) {
        chunkByteSize = file.size;
    } else {
        // 因为最多 10000 chunk，所以如果 chunkSize 不符合则把每片 chunk 大小扩大两倍
        while (file.size > chunkByteSize * 10000) {
            chunkByteSize *= 2;
        }
    }

    const chunks: Blob[] = [];
    const count = Math.ceil(file.size / chunkByteSize);
    for (let i = 0; i < count; i++) {
        const chunk = file.slice(
            chunkByteSize * i,
            i === count - 1 ? file.size : chunkByteSize * (i + 1)
        );
        chunks.push(chunk);
    }
    return chunks;
}
