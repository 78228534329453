import { Button as BsButton, ButtonProps as BsButtonProps, Spinner } from 'react-bootstrap';
import { forwardRef } from 'react';

interface ButtonProps extends BsButtonProps {
    loading?: boolean;
    percent?: number;
}

const Button = forwardRef<any, ButtonProps>((
    {
        loading,
        percent,
        disabled,
        children,
        ...props
    },
    ref
) => {

    if (loading) {
        disabled = true;
        children = <>
            <Spinner ref={ref} as='span' size='sm' role='status' aria-hidden='true' animation='border' />
            {percent ? <span className='ms-2'>{percent}%</span> : null}
        </>;
    }

    return <BsButton ref={ref} {...props} disabled={disabled}>{children}</BsButton>;
});

export default Button;
