import {
    ContainerDirectiveElement,
    DirectiveLabelElement,
    Element,
    LeafDirectiveElement,
    Node,
    TextDirectiveElement
} from 'slate';

export function isContainerDirective(node: Node, name?: string): node is ContainerDirectiveElement {
    return Element.isElementType<ContainerDirectiveElement>(node, 'containerDirective') && (!name || node.name === name);
}

export function isTextDirective(node: Node, name?: string): node is TextDirectiveElement {
    return Element.isElementType<TextDirectiveElement>(node, 'textDirective') && (!name || node.name === name);
}

export function isLeafDirective(node: Node, name?: string): node is LeafDirectiveElement {
    return Element.isElementType<LeafDirectiveElement>(node, 'leafDirective') && (!name || node.name === name);
}

export function isDirectiveLabel(node: Node, name?: string) {
    return Element.isElementType<DirectiveLabelElement>(node, 'directiveLabel') && (!name || node.name === name);
}
