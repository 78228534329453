import { Editor, Node } from 'slate';
import { getAbove } from '../../queries';
import { removeNodes } from '../../transforms';

/**
 * 插入块状元素时如果当前行为空则删除掉
 */
export function removeEmptyLineForBlock(editor: Editor, insertBlock: Function) {

    const above = getAbove(editor, { match: { type: editor.isDocument ? 'paragraph' : 'line' } });

    Editor.withoutNormalizing(editor, function() {

        insertBlock();

        if (above) {
            const [node, path] = above;
            if (Node.string(node) === '' && node.children.length === 1) {
                removeNodes(editor, { at: path });
            }
        }
    });
}
