import JsonForm, { Error, getRegistry, JsonFormProps, JsonFormType, WidgetProps } from '@topthink/json-form';
import {
    forwardRef,
    ForwardRefExoticComponent, memo,
    PropsWithoutRef,
    RefAttributes,
    useCallback,
    useContext,
    useState
} from 'react';
import { IntlContext, useAsyncEffect } from '@topwrite/common';
import isEqual from 'react-fast-compare';

const localizes = {
    en: import('ajv-i18n/localize/en'),
    'zh-CN': import('ajv-i18n/localize/zh')
};

type LocalizesType = keyof typeof localizes

export interface FormProps<T = any> extends JsonFormProps<T> {
    locale?: LocalizesType;
}

export interface FormType extends JsonFormType {

}

type Form<T = any> = ForwardRefExoticComponent<PropsWithoutRef<FormProps<T>> & RefAttributes<FormType>>

const { widgets: { TextWidget } } = getRegistry();

const widgets = {
    TextWidget: ({ schema, value, placeholder, type, ...props }: WidgetProps & { type?: string }) => {
        if (schema.encrypt) {
            type = 'password';
        }

        // @ts-ignore
        return <TextWidget schema={schema} type={type} value={value} placeholder={placeholder} {...props} />;
    }
};

const Form: Form = forwardRef(({
    children,
    locale,
    showErrorList = false,
    schema,
    ...props
}, ref) => {

    const [localize, setLocalize] = useState<any>(undefined);

    const intlContext = useContext(IntlContext);

    if (!locale) {
        if (intlContext && (intlContext.locale in localizes)) {
            locale = intlContext.locale as LocalizesType;
        } else {
            locale = 'zh-CN';
        }
    }

    useAsyncEffect(async () => {
        if (locale && (locale in localizes)) {
            const localize = (await localizes[locale]).default;
            setLocalize(() => localize);
        }
    }, []);

    const transformErrors = useCallback((errors: Error[]) => {

        errors = errors.map(error => ({
            keyword: error.name,
            dataPath: error.property,
            propertyName: error.property,
            ...error
        }));

        if (localize) {
            localize(errors);
        }
        return errors;
    }, [localize]);

    return <JsonForm
        ref={ref}
        schema={schema}
        showErrorList={showErrorList}
        widgets={widgets}
        {...props}
        transformErrors={transformErrors}
    >
        {children}
    </JsonForm>;
});

export default memo(Form, isEqual);
