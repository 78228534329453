import { AudioHTMLAttributes, forwardRef } from 'react';
import { fromAsset, isAsset } from '../../lib/asset';
import { useOptions } from '@topwrite/common';
import useFilename from '../../lib/use-filename';

const Audio = forwardRef<HTMLAudioElement, AudioHTMLAttributes<HTMLAudioElement>>(({ src, ...props }, ref) => {

    const { asset } = useOptions();

    const filename = useFilename(src);

    if (filename) {
        if (isAsset(filename)) {
            src = asset + fromAsset(filename);
        }
    }

    return <audio ref={ref} src={src} {...props} />;
});

export default Audio;
