import * as queries from './queries';
import * as transforms from './transforms';
import { Element, Node, Text, Range } from 'slate';
import * as plugins from './plugins/actions';

export type Queries = typeof queries;
export type Transforms = typeof transforms
export type PluginActions = typeof plugins

const actions: Queries & Transforms & PluginActions = {
    ...queries,
    ...transforms,
    ...plugins
};

export default function useEditorActions() {
    return {
        ...actions,
        Element,
        Node,
        Text,
        Range
    };
};
