import { clear, createStore, del, get, keys, set, UseStore } from 'idb-keyval';

export class Store {

    db: UseStore;

    constructor(dbName: string, storeName: string) {
        this.db = createStore(dbName, storeName);
    }

    async get<T>(key: IDBValidKey): Promise<T | undefined> {
        try {
            return await get(key, this.db);
        } catch {

        }
    }

    async set<T = any>(key: IDBValidKey, value: T): Promise<void> {
        try {
            await set(key, value, this.db);
        } catch {

        }
    }

    async del(key: IDBValidKey): Promise<void> {
        try {
            await del(key, this.db);
        } catch {

        }
    }

    async clear() {
        try {
            await clear(this.db);
        } catch {

        }
    }

    async has(key: IDBValidKey): Promise<boolean> {
        try {
            return await keys(this.db).then((keys) => keys.includes(key));
        } catch {

        }
        return false;
    }
}
