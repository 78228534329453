import mime from 'mime/lite';
import { socket } from '../lib/socket';
import path from 'path';
import { immerable } from 'immer';

export default class File {

    [immerable] = true;

    path: string;
    content: Buffer;

    constructor(path: string, content: Buffer) {
        this.path = path;
        this.content = content;
    }

    get mime(): string {
        return mime.getType(this.path) || 'application/octet-stream';
    }

    relative(to: string) {
        const isDirectory = to.slice(-1) === '/';

        to = path.posix.relative(
            path.posix.dirname(this.path),
            to
        ) || '.';

        return to + (isDirectory ? '/' : '');
    }

    resolve(to: string) {
        return path.posix.join(path.posix.dirname(this.path), to);
    }

    static async createFromSocket(path: string) {
        const buf = await socket.readFile(path);
        return new File(path, buf);
    }
}
