import { Editor, Path, Range } from 'slate';
import { WithQueryOptions } from '../utils/match';
import { getBlockAbove } from './get-block-above';

type AboveOptions = WithQueryOptions<typeof Editor.above, 1>

export function isRangeAcrossBlocks(editor: Editor, {
    at,
    ...options
}: Omit<AboveOptions, 'at' | 'match'> & { at?: Range | null } = {}) {
    if (!at) at = editor.selection;
    if (!at) return false;

    const [start, end] = Range.edges(at);
    const startBlock = getBlockAbove(editor, {
        at: start,
        ...options,
    });
    const endBlock = getBlockAbove(editor, {
        at: end,
        ...options,
    });

    return startBlock && endBlock && !Path.equals(startBlock[1], endBlock[1]);
}
