export * from './wrap-text';
export * from './wrap-nodes';
export * from './insert-nodes';
export * from './unwrap-nodes';
export * from './split-nodes';
export * from './set-nodes';
export * from './insert-text';

import { Editor, Transforms } from 'slate';

const { removeNodes, delete: deleteText, collapse, select, move, moveNodes, unsetNodes } = Transforms;
const { withoutNormalizing } = Editor;
export {
    deleteText,
    removeNodes,
    moveNodes,
    withoutNormalizing,
    collapse,
    select,
    move,
    unsetNodes
};
